/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms 
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import { io } from "socket.io-client";

const URL = `${process.env.REACT_APP_SOCKETIO_URL}`;
const socket = io(URL, { autoConnect: false });

// Socket IO Event Types
export const TYPE_MESSAGE = "HusebyMeeting.message";
export const EVENT_ACTIVE_APP_CHANGED = "activeAppChanged";
export const EVENT_EXHIBIT_LAUNCHED = "exhibitLaunched";
export const EVENT_EXHIBIT_MANAGER_CLOSED = "exhibitManagerClosed";
export const EVENT_EXHIBIT_EDITOR_CLOSED = "exhibitEditorClosed";
export const EVENT_SHARING_STARTED = "meeting.sharing_started";
export const EVENT_SHARING_ENDED = "meeting.sharing_ended";

const ADVANCED_MODE = 1;
const SIMPLE_MODE = 2;
const NO_EXHIBITS = 3;

const APP_SPLASH = "splash";
const APP_EXHIBIT_MANAGER = "exhibits";
const APP_EXHIBIT_EDITOR = "exhibitEditor";
const APP_TRANSCRIPTS = "transcripts";
const APP_SCREENSHARE = "screenshare";
const APP_ZOOM = "zoom";

export let room = {
  roomId: null,
  userId: -1,
  name: null,
  username: null,
  role: null,
  attendees: [],
  permissions: [],
};

export let attendees = [];

//
// Socket Event Handlers
//
socket.on("session", ({ sessionID, roomId, userId, username, name, role }) => {
  // attach the session ID to the next reconnection attempts
  console.log("handleNewSessionId", sessionID, roomId, userId, username, name, role);
  socket.auth = { sessionID, userId, username, name, role, roomId };
  socket.userId = userId;
  room.userId = userId;
  room.name = name;
});

socket.on("users", (users) => {
  console.log("users", users);
  attendees = users;
});

socket.on("user connected", (user) => {
  console.log("user connected", user);
});

socket.on("roleChanged", (data) => {
  console.log("role changed", data);
  // if (data.username === room.username) setPermissions(data.role);
});

socket.on("connect", async () => {
  console.log("Handling socket connection...");
  console.log("-- attempting to join room...", room);

  joinRoom(room.userId, room.username, room.name, room.roomId, room.role);
});

socket.on("connect_error", () => {
  console.log("Handling socket connection error...");
});

socket.on("disconnect", () => {
  console.log("Handling socket disconnect error...");
});

/**
 * Join a HusebyConnect Room.
 *
 * @param {*} userId
 * @param {*} username
 * @param {*} name
 * @param {*} roomId
 * @param {*} role
 */
export const joinRoom = (userId, username, name, roomId, role) => {
  console.log("Joining room...", userId, username, name, roomId, role);

  socket.auth = { userId, username, name, roomId, role };
  socket.connect();

  console.log("Connected user to room.", socket);
};

// Socket.IO Emitters
export const emitRoleChanged = (e) => {
  console.log("emitRoleChanged", e);
  const message = {
    userId: e.userId,
    username: e.username,
    role: e.role,
  };
  socket.emit("roleChanged", { data: message, roomId: room.roomId });
};

export const emitActiveAppChanged = (event, selectedApp, presenter) => {
  console.log("emitActiveAppChanged", event, selectedApp, presenter);
  const message = {
    type: EVENT_ACTIVE_APP_CHANGED,
    data: selectedApp,
    event: event,
    src: presenter,
  };

  socket.emit(TYPE_MESSAGE, { data: message, roomId: event.eventId });
};

export const emitExhibitManagerClosed = (username, eventId, isHost) => {
  const message = {
    username: username,
    type: EVENT_EXHIBIT_MANAGER_CLOSED,
    data: eventId,
  };

  if (isHost) {
    socket.emit(TYPE_MESSAGE, {
      data: message,
      roomId: eventId,
    });
  }
};

export const emitExhibitEditorClosed = (username, eventId, isHost) => {
  const message = {
    username: username,
    type: EVENT_EXHIBIT_EDITOR_CLOSED,
    data: null,
  };

  if (isHost) {
    socket.emit(TYPE_MESSAGE, {
      data: message,
      roomId: eventId,
    });
  }
};

/**
 * Handle synchronizing the application state when the application
 * is loaded for the first time or when a browser refresh occurs.
 */
export const handleInitAppState = (
  socket,
  username,
  setLockExhibits,
  setActiveApp,
  setLaunchedExhibit,
  setExhibitPresenter,
  setShowExhibitManagerLockedDialog
) => {
  socket.on("syncAppState", (msg) => {
    console.log("Synchronizing the application state from RoomServer...");
    const appState = JSON.parse(msg);

    // The ExhibitEditor is the only application that should be synchronized.
    // We do not want to force users to launch the TranscriptViewer and ZoomMeeting
    console.log(`The current active app = ${appState.activeApp}`);
    if (appState.activeApp === APP_EXHIBIT_EDITOR) {
      const isLocked = appState.presenter.username !== username;
      setLockExhibits(isLocked);
      setActiveApp(appState.activeApp);
      setLaunchedExhibit(appState.launchedExhibit);
      setExhibitPresenter(appState.presenter);

      // If the activeApp is the ExhibitManager and the presenter is not the logged-in user,
      // then display the locked message.
      // Else If the activeApp is the ExhibitManager and the presenter is the logged-in user,
      // then display the ExhibitManager screen.
    } else if (appState.activeApp === APP_EXHIBIT_MANAGER && appState.presenter.username !== username) {
      console.log(`${appState.presenter.username} is presenting an exhibit.  Locking the Exhibit Manager.`);
      console.log("Setting the local activeApp to Splash screen...");
      setLockExhibits(true);
      setActiveApp(APP_SPLASH);
      setExhibitPresenter(appState.presenter);
      setShowExhibitManagerLockedDialog(true);
    } else if (appState.activeApp === APP_EXHIBIT_MANAGER && appState.presenter.username === username) {
      console.log("Setting the local activeApp to ExhibitManager...");
      setLockExhibits(false);
      setActiveApp(appState.activeApp);
      setExhibitPresenter(appState.presenter);
    } else if (appState.activeApp === APP_SPLASH) {
      setActiveApp(appState.activeApp);
      setLockExhibits(false);
    } else {
      setLockExhibits(true);
    }
  });
};

/**
 *
 * @param {*} socket
 */
export const handleActiveApplicationMsg = (
  socket,
  username,
  setLockExhibits,
  setActiveApp,
  setLaunchedExhibit,
  setExhibitPresenter,
  setShowExhibitManagerLockedDialog,
  handleExhibitManagerClose,
  handleExhibitEditorClose
) => {
  socket.on(TYPE_MESSAGE, (msg) => {
    if (msg.type === EVENT_ACTIVE_APP_CHANGED) {
      if (msg.event.exhibits === ADVANCED_MODE && msg.data === APP_EXHIBIT_MANAGER && msg.src.username !== username) {
        console.log(`${msg.src.username} is presenting an exhibit.  Locking the Exhibit Manager.`);
        setLockExhibits(true);
        setExhibitPresenter(msg.src);
        setShowExhibitManagerLockedDialog(true);
      } else if (msg.data !== APP_TRANSCRIPTS) {
        setActiveApp(msg.data);
      }
    } else if (msg.type === EVENT_EXHIBIT_LAUNCHED) {
      const isLocked = msg.src.username !== username;
      setLockExhibits(isLocked);
      setLaunchedExhibit(msg.data);
      setExhibitPresenter(msg.src);
      setShowExhibitManagerLockedDialog(false);
      setActiveApp(APP_EXHIBIT_EDITOR);
    } else if (msg.type === EVENT_EXHIBIT_MANAGER_CLOSED) {
      setLockExhibits(false);
      setShowExhibitManagerLockedDialog(false);
      handleExhibitManagerClose();
    } else if (msg.type === EVENT_EXHIBIT_EDITOR_CLOSED) {
      setLockExhibits(false);
      setShowExhibitManagerLockedDialog(false);
      handleExhibitEditorClose();
    }
  });
};

/**
 * Handle "permissionsChanged" web socket event.
 *
 * @param {*} socket
 */
export const handlePermissionsChanged = (
  socket,
  userId,
  eventParticipant,
  setEnableTranscripts,
  setShowTranscriptsTile
) => {
  socket.on("permissionsChanged", (permissionsList) => {
    let permissions = permissionsList.permissions.find((p) => p.contact.userId === userId);
    // console.log("XXX permissions", userId, permissions);
    // console.log("XXX eventParticipant", eventParticipant);

    // If the grantViewTranscripts has changed for this user and the user is not an internal user,
    // then show the transcripts tile in the meeting app.
    if (
      eventParticipant.grantViewTranscripts != permissions.grantViewTranscripts &&
      eventParticipant.eventParticipantType.eventParticipantTypeId != 1
    ) {
      // console.log(
      //   "XXX permissions.grantViewTranscripts changed...",
      //   eventParticipant.grantViewTranscripts,
      //   permissions.grantViewTranscripts
      // );
      setEnableTranscripts(
        permissions.grantViewTranscripts || permissions.eventParticipantType.eventParticipantTypeId === 1
      );

      setShowTranscriptsTile(
        permissions.grantViewTranscripts || permissions.eventParticipantType.eventParticipantTypeId === 1
      );
    }
  });
};

export default socket;
