/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import React from "react";
import moment from "moment";
import { instance as http } from "@cirrux888/huseby-client-auth";
import { isNil, merge } from "lodash";

const DELAY = 800;

let reducer = (data, newData) => {
  newData.clear && delete data[newData.clear] && delete newData.clear;
  return { ...merge(data, newData) };
};

const initialState = {
  event: null,
  case: null,
  role: "participant",
  showExhibitsTile: true,
  showZoomTile: true,
  showTranscriptsTile: false,
  enableTranscripts: false,
  activeApp: "splash",
  isAppExpanded: false,
  lockExhibits: true,
  exhibitPresenter: { name: null, username: null },
  eventParticipant: null,
  screenShareStatus: null,
};

const DataContext = React.createContext();

const DataProvider = (props) => {
  const [data, setData] = React.useReducer(reducer, initialState);

  const getContact = async (contactId) => {
    const config = {
      method: "get",
      url: `/hc/contacts/${contactId}`,
    };
    try {
      const { data: contact } = await http(config);
      return contact;
    } catch (error) {
      console.error("error", error);
      throw error;
    }
  };

  const getEvent = async (eventId) => {
    const config = {
      method: "get",
      url: `/hc/events/${eventId}`,
    };
    try {
      setData({ loading: true });
      const { data: eventData } = await http(config);
      setData({ event: eventData });
      return eventData;
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  const getEventByMeetingUrl = async (meetingUrl) => {
    const config = {
      method: "get",
      url: `/hc/events/by-url?meetingUrl=${meetingUrl}`,
    };
    try {
      setData({ loading: true });
      const { data: eventData } = await http(config);
      setData({ event: eventData });
      return eventData;
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  const getEventParticipant = async (eventId, contactId) => {
    const config = {
      method: "get",
      url: `/hc/events/${eventId}/participants/${contactId}`,
    };

    try {
      setData({ loading: true });
      const { data: eventParticipant } = await http(config);
      setData({ eventParticipant: eventParticipant });
      return eventParticipant;
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  const createTicket = async (ticketDetails) => {
    const config = {
      method: "post",
      url: `/contacts/support`,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(ticketDetails),
    };
    try {
      setData({ loading: true });
      const { data: ticket } = await http(config);
      setData({ ticket });
    } catch (error) {
      console.error(error);
    } finally {
      setTimeout(() => setData({ loading: false }), DELAY);
    }
  };

  const listBookmarks = async () => {
    console.log("DataService.listBookmarks");
    return [
      { name: "Test Bookmark 1", type: "pdf" },
      { name: "Test Bookmark 2", type: "pdf" },
    ];
  };

  const setActiveApp = async (activeApp) => {
    console.log("DataService.setActiveApp", activeApp, "and DataService.expandActiveApp", false);
    try {
      // if we set the active app, we want to make sure it's not expanded
      // expanding an app will be explicitly handled by `expandActiveApp`
      setData({ clear: "isAppExpanded" }); // clear isAppExpanded
      setData({ isAppExpanded: false });
      setData({ clear: "activeApp" });
      setData({ activeApp });
    } catch (error) {
      console.log(error);
    }
  };

  // Behavior of the tiles.
  // If the active app will show up fullscreen or take only its initial size.
  const expandActiveApp = async (isAppExpanded) => {
    console.log("DataService.setActiveApp", data.activeApp, "and DataService.expandActiveApp", isAppExpanded);
    try {
      setData({ clear: "isAppExpanded" });
      setData({ isAppExpanded });
    } catch (error) {
      console.log(error);
    }
  };

  const setLockExhibits = async (lock) => {
    setData({ clear: "lockExhibits" });
    setData({ lockExhibits: lock });
  };

  const setLaunchedExhibit = (launchedExhibit) => {
    setData({ clear: "launchedExhibit" });
    setData({ launchedExhibit: launchedExhibit });
  };

  const setExhibitPresenter = (presenter) => {
    setData({ clear: "exhibitPresenter" });
    setData({ exhibitPresenter: presenter });
  };

  const setShowExhibitsTile = (show) => {
    setData({ clear: "showExhibitsTile" });
    setData({ showExhibitsTile: show });
  };

  const setShowZoomTile = (show) => {
    setData({ clear: "showZoomTile" });
    setData({ showZoomTile: show });
  };

  const setShowTranscriptsTile = (show) => {
    setData({ clear: "showTranscriptsTile" });
    setData({ showTranscriptsTile: show });
  };

  const setScreenShareStatus = (status) => {
    setData({ clear: "screenShareStatus" });
    setData({ screenShareStatus: status });
  };

  const setEnableTranscripts = (enable) => {
    setData({ clear: "enableTranscripts" });
    setData({ enableTranscripts: enable });
  };

  return (
    <DataContext.Provider
      value={{
        data,
        setData,
        getContact,
        createTicket,
        getEvent,
        getEventByMeetingUrl,
        getEventParticipant,
        listBookmarks,
        setActiveApp,
        expandActiveApp,
        setLockExhibits,
        setLaunchedExhibit,
        setExhibitPresenter,
        setShowExhibitsTile,
        setShowTranscriptsTile,
        setShowZoomTile,
        setScreenShareStatus,
        setEnableTranscripts,
      }}
    >
      {props.children}
    </DataContext.Provider>
  );
};

export { DataContext, DataProvider };
