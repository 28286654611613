import React, { Suspense, lazy } from "react";
import { Mosaic, MosaicWindow, MosaicContext } from "react-mosaic-component";
import "react-mosaic-component/react-mosaic-component.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import { useParams, useLocation } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import {
  AppBar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import RemoveIcon from "@mui/icons-material/Remove";
import FullScreenModeIcon from "@mui/icons-material/Fullscreen";
import NormalScreenModeIcon from "@mui/icons-material/FullscreenExit";
import Header from "./common/Header";
import { Default } from "../components/common/Responsive";
import CRXDialogTitle from "./common/dialog/CRXDialogTitle";
import SimpleLoader from "./common/SimpleLoader";
import { DataContext } from "../services/DataService";
import { AuthContext } from "@cirrux888/huseby-client-auth";
import socket, {
  room,
  joinRoom,
  EVENT_SHARING_STARTED,
  EVENT_SHARING_ENDED,
  emitExhibitManagerClosed,
  emitExhibitEditorClosed,
  emitActiveAppChanged,
  handleInitAppState,
  handleActiveApplicationMsg,
  handlePermissionsChanged,
} from "./useSocket";
import theme from "../theme";
import Iframe from "react-iframe";
import { isNil } from "lodash";

const ExhibitManagerDialog = lazy(() => import("./exhibits/ExhibitManager"));
const ExhibitEditorDialog = lazy(() => import("./exhibits/ExhibitEditor"));
const TranscriptsViewerDialog = lazy(() => import("./transcripts/TranscriptsViewer"));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const APP_SPLASH = "splash";
const APP_EXHIBIT_MANAGER = "exhibits";
const APP_EXHIBIT_EDITOR = "exhibitEditor";
const APP_TRANSCRIPTS = "transcripts";
const APP_SCREENSHARE = "screenshare";
const APP_ZOOM = "zoom";

const MOSAIC_DEFAULT_LAYOUT = {
  direction: "row",
  first: "exhibits",
  second: "zoom",
};

const MOSAIC_TRANSCRIPTS_LAYOUT = {
  direction: "row",
  first: "exhibits",
  second: {
    direction: "column",
    first: "zoom",
    second: "transcripts",
  },
};

const MOSAIC_EXHIBITS_TRANSCRIPTS_LAYOUT = {
  direction: "row",
  first: "exhibits",
  second: "transcripts",
};

const MOSAIC_ZOOM_TRANSCRIPTS_LAYOUT = {
  direction: "row",
  first: "zoom",
  second: "transcripts",
};

const MOSAIC_EXHIBITS_ONLY_LAYOUT = "exhibits";
const MOSAIC_TRANSCRIPTS_ONLY_LAYOUT = "transcripts";
const MOSAIC_ZOOM_ONLY_LAYOUT = "zoom";

const ADVANCED_MODE = 1;
const SIMPLE_MODE = 2;
const NO_EXHIBITS = 3;

const useStyles = makeStyles((props) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    zIndex: 0,
  },
  title: {
    flexGrow: 1,
  },
  hide: {
    display: "none",
  },
  drawer: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
  },
  splashTitle: {
    color: "#ffffff",
    fontSize: 50,
  },
  subTitle: {
    color: "#ffffff",
    fontSize: 20,
    textAlign: "center",
  },

  /// Splash screen styles
  card: {
    width: "90%",
    // maxWidth: 600,
    marginLeft: 40,
    marginRight: 40,
    padding: 20,
    display: "flex",
    flexDirection: "column",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  splashTitle: {
    fontSize: 50,
  },
  subTitle: {
    fontSize: 20,
    textAlign: "center",
  },
  title: {
    fontSize: 18,
    fontWeight: "normal",
    cursor: "default",
  },
  cardHeader: {
    header: 30,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  media: {
    maxHeight: 150,
    padding: 16,
    textAlign: "center",
    "& img": {
      height: "100%",
    },
    "@media(max-height: 500px)": {
      flex: "1 1 0",
      "& img": {
        width: "100%",
      },
    },
  },
  cardContent: {
    "@media(max-height: 500px)": {
      flex: "1 1 0",
    },
  },
  cardContentWrapper: {
    display: "flex",
    flexDirection: "column",
    "@media(max-height: 500px)": {
      flexDirection: "row",
    },
  },
  cardActions: {
    width: "100%",
    justifyContent: "center",
  },
  pos: {
    marginBottom: 12,
  },
}));

const DesktopIndex = () => {
  const query = useQuery();
  const [loading, setLoading] = React.useState();
  const [notAuthorized, setNotAuthorized] = React.useState();
  const [open, setOpen] = React.useState(false);
  const [mosaicLayout, setMosaicLayout] = React.useState(MOSAIC_DEFAULT_LAYOUT);
  const [prevMosaicLayout, setPrevMosaicLayout] = React.useState(MOSAIC_DEFAULT_LAYOUT);
  const { meetingUrl, fullscreenApp } = useParams();
  const classes = useStyles();
  const { getIdentity } = React.useContext(AuthContext);
  const {
    data,
    setData,
    getContact,
    setActiveApp,
    setLockExhibits,
    setLaunchedExhibit,
    setExhibitPresenter,
    getEventByMeetingUrl,
    getEventParticipant,
    setEnableTranscripts,
    setShowExhibitsTile,
    setShowZoomTile,
    setShowTranscriptsTile,
    setScreenShareStatus,
  } = React.useContext(DataContext);

  const [_case, setCase] = React.useState(null);
  const [event, setEvent] = React.useState(null);
  const [isHost, setIsHost] = React.useState();
  const [isFullscreen, setIsFullscreen] = React.useState(false);
  const [exhibitsMode, setExhibitsMode] = React.useState(ADVANCED_MODE);
  const [showExhibitManagerLockedDialog, setShowExhibitManagerLockedDialog] = React.useState(false);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        console.log("Getting logged-in user's identity...");
        const identity = JSON.parse(getIdentity());
        const userId = identity.userId;
        const username = identity.username;
        const fullName = identity.name;

        // Set MyContact
        const contactId = JSON.parse(getIdentity()).contactId;
        const contact = await getContact(contactId);
        setData({ myContact: contact });

        // Get event by meetingUrl
        console.log("Getting event by meetingUrl...");
        const results = await getEventByMeetingUrl(meetingUrl);
        const _event = results.event;
        const _case = results.myCase;
        const _eventParticipant = results.eventParticipant;
        setCase(_case);
        setEvent(_event);
        setExhibitsMode(_event.exhibits);
        const eventId = _event.eventId;
        console.log("Event fetched...", _event);

        // Get event participant info
        console.log("Getting event participant info...");
        const eventParticipant = await getEventParticipant(eventId, identity.contactId);
        setData({ eventParticipant: eventParticipant });
        console.log("eventParticipant", eventParticipant);

        let role = "participant";
        if (
          eventParticipant.eventParticipantType.eventParticipantTypeId === 1 || // Internal
          eventParticipant.eventParticipantType.eventParticipantTypeId === 2 // Firm Contact
        ) {
          setIsHost(true);
          role = "host";
        }

        // Set role
        setData({ role: role });
        // Hide/show exhibits if enableExhibits = true
        setShowExhibitsTile(_event.exhibits > 0 ? true : false);

        // Hide/show meeting if enableMeeting = true
        setShowZoomTile(_event.enableMeeting);
        if (eventParticipant.contact.contactTypeId == 103 || eventParticipant.contact.contactTypeId == 7) {
          setShowTranscriptsTile(false);
        } else {
          setShowTranscriptsTile(_event.transcripts === 1 || _event.transcripts === 2 ? true : false);
        }

        // Set transcripts layout if isRealTimeTextEnabled=true
        if (
          (_event.transcripts === 1 || _event.transcripts === 2) &&
          (eventParticipant.grantViewTranscripts === true ||
            eventParticipant.eventParticipantType.eventParticipantTypeId === 1)
        ) {
          setMosaicLayout(MOSAIC_TRANSCRIPTS_LAYOUT);
          setEnableTranscripts(true);
          setShowTranscriptsTile(true);
        }

        // set app variables
        setData({ eventId });
        setData({ event: _event });
        setData({ case: _case });

        // Get fullscreen app
        console.log("Checking if fullscreen app is requested...", fullscreenApp);
        if (fullscreenApp) {
          setActiveApp(fullscreenApp);
          setIsFullscreen(true);
        }

        console.log("Checking if SocketIO session exists...");
        const roomId = eventId;

        // Set room data
        room.userId = userId;
        room.username = username;
        room.name = fullName;
        room.roomId = roomId;
        room.role = role;
        // Register handlers for SocketIO messages if we are in ADVANCED_MODE.
        if (_event.exhibits === ADVANCED_MODE) {
          console.log("Exhibits is in ADVANCED_MODE.   Enabling web socket for co-browsing...");
          joinRoom(room.userId, room.username, room.name, room.roomId, room.role);

          console.log("Registering web socket handlers...");
          handleInitAppState(
            socket,
            username,
            setLockExhibits,
            setActiveApp,
            setLaunchedExhibit,
            setExhibitPresenter,
            setShowExhibitManagerLockedDialog
          );
          handleActiveApplicationMsg(
            socket,
            username,
            setLockExhibits,
            setActiveApp,
            setLaunchedExhibit,
            setExhibitPresenter,
            setShowExhibitManagerLockedDialog,
            handleExhibitManagerClose,
            handleExhibitEditorClose
          );
          handleZoomWebhookMessages(socket);
          handlePermissionsChanged(socket, userId, eventParticipant, setEnableTranscripts, setShowTranscriptsTile);
        }

        // End of SocketIO
        setNotAuthorized(false);
      } catch (error) {
        setNotAuthorized(true);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  /**
   * Handle the Zoom webhook for screensharing.
   */
  const handleZoomWebhookMessages = (socket) => {
    socket.on("webhook", (data) => {
      console.log("Handling Zoom webhook for screensharing...", data.event);
      if (data.event === EVENT_SHARING_STARTED) {
        setOpen(true);
        setScreenShareStatus("started");
      } else if (data.event === EVENT_SHARING_ENDED) {
        setOpen(false);
        setScreenShareStatus("ended");
      }
    });
  };

  /**
   * Event handler for when the Exhibit Manager is closed.
   */
  const handleExhibitManagerClose = () => {
    setLaunchedExhibit(data.launchedExhibit);

    if (!isFullscreen) {
      setActiveApp(APP_SPLASH);
    }

    emitExhibitManagerClosed(getIdentity().username, data.eventId, isHost);
  };

  /**
   * Event handler for when the Exhibit Editor is closed.
   */
  const handleExhibitEditorClose = () => {
    setLaunchedExhibit(data.launchedExhibit);

    setActiveApp(APP_SPLASH);
  };

  const updateTiles = () => {
    if (data.showExhibitsTile && data.showZoomTile && data.showTranscriptsTile) {
      setMosaicLayout(MOSAIC_TRANSCRIPTS_LAYOUT);
    } else if (data.showExhibitsTile && data.showZoomTile && !data.showTranscriptsTile) {
      setMosaicLayout(MOSAIC_DEFAULT_LAYOUT);
    } else if (data.showExhibitsTile && !data.showZoomTile && data.showTranscriptsTile) {
      setMosaicLayout(MOSAIC_EXHIBITS_TRANSCRIPTS_LAYOUT);
    } else if (data.showExhibitsTile && !data.showZoomTile && !data.showTranscriptsTile) {
      setMosaicLayout(MOSAIC_EXHIBITS_ONLY_LAYOUT);
    } else if (!data.showExhibitsTile && data.showZoomTile && data.showTranscriptsTile) {
      setMosaicLayout(MOSAIC_ZOOM_TRANSCRIPTS_LAYOUT);
    } else if (!data.showExhibitsTile && !data.showZoomTile && data.showTranscriptsTile) {
      setMosaicLayout(MOSAIC_TRANSCRIPTS_ONLY_LAYOUT);
    } else if (!data.showExhibitsTile && data.showZoomTile && !data.showTranscriptsTile) {
      setMosaicLayout(MOSAIC_ZOOM_ONLY_LAYOUT);
    }
  };

  React.useEffect(() => {
    updateTiles();
  }, [data.showExhibitsTile, data.showZoomTile, data.showTranscriptsTile]);

  return (
    <>
      {!loading && notAuthorized === false && !isFullscreen && (
        <div className="app-container">
          <div className="header">
            <AppBar position="fixed" className={classes.appBar}>
              <Header />
            </AppBar>
          </div>
          <div className="main" style={{ height: "calc(100vh - 55px)" }}>
            {(data.showExhibitsTile || data.showZoomTile || data.showTranscriptsTile) && (
              <Mosaic
                renderTile={Tile}
                initialValue={mosaicLayout}
                value={mosaicLayout}
                onChange={(node) => {
                  setData({ resizeOverlay: true });
                }}
                onRelease={(node) => {
                  setData({ resizeOverlay: false });
                }}
              ></Mosaic>
            )}

            {!data.showExhibitsTile && !data.showZoomTile && !data.showTranscriptsTile && (
              <h1 style={{ color: "#000000" }}>No applications selected</h1>
            )}
          </div>
        </div>
      )}

      {!loading && notAuthorized && <NotAuthorizedMessage />}

      {!loading && isFullscreen && (
        <div className="main" style={{ height: "calc(100vh)" }}>
          {fullscreenApp === "exhibits" && <ExhibitsTileIframe isFullscreen={true} />}
          {fullscreenApp === "transcripts" && <TranscriptsTileIframe isFullscreen={true} />}
          {fullscreenApp === "zoom" && <ZoomTile isFullscreen={true} />}
        </div>
      )}

      {showExhibitManagerLockedDialog && data.exhibitPresenter?.name && (
        <ExhibitManagerLockedDialog
          open={showExhibitManagerLockedDialog}
          exhibitPresenter={data.exhibitPresenter?.name}
          handleClose={() => setShowExhibitManagerLockedDialog(false)}
        />
      )}
    </>
  );
};

/**
 * The Tile.  This is a wrapper for the MosaicWindow.   Depending on
 * what the id is, it will display the ExhibitsTile, the ZoomTile or
 * the TranscriptsTile.
 *
 * @param {*} id
 * @param {*} path
 * @returns
 */
const Tile = (id, path) => (
  <MosaicWindow
    toolbarControls={[]}
    renderToolbar={(props, draggable) => {
      return (
        <div
          style={{
            width: "100%",
            paddingLeft: "10px",
            paddingRight: "10px",
            paddingTop: "5px",
            paddingBotton: "5px",
          }}
        >
          <TileToolbar props={props} draggable={draggable} />
        </div>
      );
    }}
    id={id}
    path={path}
    title={id}
  >
    {id === "exhibits" && <ExhibitsTileIframe />}
    {id === "zoom" && <ZoomTile />}
    {id === "transcripts" && <TranscriptsTileIframe />}
  </MosaicWindow>
);

/**
 * Render the Tile's toolbar.  The toolbar provides the following features:
 * - Launch application in tab - Clicking on this button displays the Tile in a separate tab.
 * - Hide/minimize - Clicking on this button hides the Tile.
 * - Normal view - Clicking on this button displays the Tile in normal viewing mode,
 *   which is 50% of the screen.
 * - Full screen (maximize) - Clicking on this button displays the Tile in full screen mode,
 *   which is 100% of the screen.
 *
 * @param {*} props
 * @param {*} draggable
 * @returns
 */
const TileToolbar = ({ props, draggable }) => {
  const { path, id } = props;
  const { data, expandActiveApp, setExhibitPresenter, setShowExhibitsTile, setShowZoomTile, setShowTranscriptsTile } =
    React.useContext(DataContext);
  const [mosaicActions, setMosaicActions] = React.useState(null);
  const [activeApp, setActiveApp] = React.useState(null);

  /**
   * Handle when the screenshare status is updated to 'started' or
   * 'ended'.   When it is updated to 'started', then maximize the
   * Zoom tile.  When it is updated to 'ended', then change the Zoom
   * tile back to normal size.
   */
  React.useEffect(() => {
    if (id === "zoom" && data.screenShareStatus === "started") {
      handleMaximize(id, path);
    } else if (id === "zoom" && data.screenShareStatus === "ended") {
      handleMinimize(path);
    }
  }, [data.screenShareStatus]);

  /**
   * Handle launch the active app in a new tab.  There are two things to consider here.  The
   * `selectedApp` refers to the tile that is being displayed in a new browser tab and
   * can be 'exhibits', 'zoom', or 'transcripts'.   However, for 'exhibits' tile, there
   * are three applications that can be displayed, which are ExhibitManager ('exhibits'),
   * ExhibitEditor ('exhibitEditor') and Splash screen ('splash').  This is defined by `data.activeApp`.
   *
   *
   * @param {*} selectedApp
   */
  const handleLaunchActiveAppInTab = (selectedApp) => {
    let meetingUrl = data.event.acMeetingUrl;
    meetingUrl = meetingUrl.replaceAll("/", "");
    window.open(`/rm/${meetingUrl}/${selectedApp}`, "_blank");

    // Hide the tile.
    mosaicActions.hide(path);

    // Set the tile state
    setShowTileFlag(selectedApp, false);

    // We pass in `data.activeApp` to setActiveApp because for 'exhibits' tile, there
    // are three applications that can be displayed, which are ExhibitManager ('exhibits'),
    // ExhibitEditor ('exhibitEditor') and Splash screen ('splash').  This is defined by
    // `data.activeApp`.  If we were to pass in `selectedApp`, it would always force
    // the ExhibitManager to be displayed when launching in a new tab.
    setActiveApp(data.activeApp);
  };

  const handleHide = (path) => {
    if (isNil(mosaicActions)) return;

    setShowTileFlag(id, false);

    mosaicActions.hide(path);
  };

  const handleMinimize = (path) => {
    if (isNil(mosaicActions)) return;
    mosaicActions.expand(path, 50);
    expandActiveApp(false);
  };

  const handleMaximize = (id, path) => {
    if (isNil(mosaicActions)) return;

    if (id === "exhibits") {
      expandActiveApp(true);
    } else {
      expandActiveApp(false);
    }
    mosaicActions.expand(path, 100);
  };

  const setShowTileFlag = (id, show) => {
    if (id === APP_EXHIBIT_MANAGER) setShowExhibitsTile(show);
    else if (id === APP_ZOOM) setShowZoomTile(show);
    else if (id === APP_TRANSCRIPTS) setShowTranscriptsTile(show);
  };

  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between">
      <MosaicContext.Consumer>
        {({ mosaicActions }) => {
          setMosaicActions(mosaicActions);

          return (
            <>
              <Box>
                <Tooltip title="Extend application into separate browser tab">
                  <IconButton size="small" onClick={() => handleLaunchActiveAppInTab(id)}>
                    <OpenInNewIcon fontSize="small" color="primary" />
                    {/* {id} */}
                  </IconButton>
                </Tooltip>
              </Box>
              <Box>
                <Tooltip title="Hide tile">
                  <IconButton
                    size="small"
                    onClick={() => {
                      handleHide(path);
                      setShowTileFlag(id, false);
                    }}
                  >
                    <RemoveIcon fontSize="small" color="primary" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Normal view">
                  <IconButton
                    size="small"
                    onClick={() => {
                      handleMinimize(path);
                      setShowTileFlag(id, true);
                    }}
                  >
                    <NormalScreenModeIcon fontSize="small" color="primary" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Fullscreen view">
                  <IconButton size="small" onClick={() => handleMaximize(id, path)}>
                    <FullScreenModeIcon fontSize="small" color="primary" />
                  </IconButton>
                </Tooltip>
              </Box>
            </>
          );
        }}
      </MosaicContext.Consumer>
    </Box>
  );
};

/**
 * The Exhibits IFrame - Fix for 2777.   The root cause is that the ExhibitsEditor and Zoom meeting
 * component are sharing the same CPU and memory resources, which results in a perceivable video/audio
 * lag when an exhibit is being loaded into the ExhibitsEditor.
 *
 * The fix is based on the ‘Origin-Agent-Cluster' HTTP Header and an iframe for the ExhibitsEditor component.
 * Please see this article for more information:  https://web.dev/origin-agent-cluster/
 *
 * By moving the ExhibitsEditor into its own iframe and passing the ‘Origin-Agent-Cluster’ HTTP Header,
 * browsers that support this header will know to allocate additional/separate CPU and memory resources
 * for the ExhibitEditor.
 *
 * @returns
 */

const ExhibitsTileIframe = ({ isFullscreen = false }) => {
  const { data } = React.useContext(DataContext);

  return (
    <>
      <Box style={{ height: "100%", width: "100%" }}>
        <Iframe
          url={`${process.env.REACT_APP_EXHIBITEDITOR_URL}/rm/${data.event.acMeetingUrl.replaceAll(
            "/",
            ""
          )}/exhibitsApp`}
          id="myId"
          className="meeting-iframe"
          display="initial"
          position="relative"
          styles={{ overflowY: "auto", height: "100%", width: "100%", borderWidth: "0px" }}
        />
      </Box>
      {/* We need to add a <div> overlay to make resizing with Mosaic smoother. */}
      <div
        style={{
          visibility: data.resizeOverlay ? "visible" : "hidden",
          zIindex: "100",
          position: "absolute",
          top: "0",
          right: "0",
          backgroundColor: "rgba(0, 0, 0, 0.3)",
          width: "100%",
          height: "100%",
          textAlign: "center",
        }}
      ></div>
    </>
  );
};

/**
 * The Exhibits tile.
 *
 * @returns
 */

export const ExhibitsTile = () => {
  const [activeStageApp, setActiveStageApp] = React.useState(APP_SPLASH);
  const { data, setActiveApp, expandActiveApp, setLaunchedExhibit } = React.useContext(DataContext);
  const { getIdentity } = React.useContext(AuthContext);
  const [isHost, setIsHost] = React.useState(false);

  const [loading, setLoading] = React.useState(true);
  const [notAuthorized, setNotAuthorized] = React.useState();
  const { meetingUrl, fullscreenApp } = useParams();
  const { setData, getContact, setLockExhibits, setExhibitPresenter, getEventByMeetingUrl, getEventParticipant } =
    React.useContext(DataContext);

  const [_case, setCase] = React.useState(null);
  const [event, setEvent] = React.useState(null);
  const [isFullscreen, setIsFullscreen] = React.useState(false);
  const [showExhibitManagerLockedDialog, setShowExhibitManagerLockedDialog] = React.useState(false);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        console.log("ExhibitsTile.Getting logged-in user's identity...");
        const identity = JSON.parse(getIdentity());
        const userId = identity.userId;
        const username = identity.username;
        const fullName = identity.name;

        // Set MyContact
        const contactId = JSON.parse(getIdentity()).contactId;
        const contact = await getContact(contactId);
        setData({ myContact: contact });

        // Get event by meetingUrl
        console.log("ExhibitsTile.Getting event by meetingUrl...");
        const results = await getEventByMeetingUrl(meetingUrl);
        const _event = results.event;
        const _case = results.myCase;
        setCase(_case);
        setEvent(_event);
        const eventId = _event.eventId;
        console.log("ExhibitsTile.Event fetched...", _event);

        // set app variables
        setData({ eventId });
        setData({ event: _event });
        setData({ case: _case });

        // Get fullscreen app
        console.log("ExhibitsTile.Checking if fullscreen app is requested...", fullscreenApp);
        if (fullscreenApp) {
          setActiveApp(fullscreenApp);
          setIsFullscreen(true);
        }

        //
        console.log("ExhibitsTile.Getting event participant info...");
        const eventParticipant = await getEventParticipant(eventId, identity.contactId);
        console.log("ExhibitsTile.eventParticipant", eventParticipant);

        // Check if this user does not have permissions for the Event.  If
        // not, then display the error message.
        // QW-TODO: ...

        let role = "participant";
        if (
          eventParticipant.eventParticipantType.eventParticipantTypeId === 1 || // Internal
          eventParticipant.eventParticipantType.eventParticipantTypeId === 2 // Firm Contact
        ) {
          setIsHost(true);
          role = "host";
        }

        // Set role
        setData({ role: role });

        console.log("Checking if SocketIO session exists...");
        const roomId = eventId;

        // Set room data
        room.userId = userId;
        room.username = username;
        room.roomId = roomId;
        room.role = role;
        room.name = fullName;

        // Register web socket if we are in ADVANCED_MODE.
        if (_event.exhibits === ADVANCED_MODE) {
          console.log("Exhibits is in ADVANCED_MODE.   Enabling web sockets for co-browsing...");
          joinRoom(room.userId, room.username, room.name, room.roomId, room.role);
          console.log("ExhibitsTile.Connected.", socket);

          handleInitAppState(
            socket,
            username,
            setLockExhibits,
            setActiveApp,
            setLaunchedExhibit,
            setExhibitPresenter,
            setShowExhibitManagerLockedDialog
          );
          handleActiveApplicationMsg(
            socket,
            username,
            setLockExhibits,
            setActiveApp,
            setLaunchedExhibit,
            setExhibitPresenter,
            setShowExhibitManagerLockedDialog,
            handleExhibitManagerClose,
            handleExhibitEditorClose
          );
        }

        // End of SocketIO
        setNotAuthorized(false);
      } catch (error) {
        setNotAuthorized(true);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  ///////

  React.useEffect(() => {
    // Keep track of the previous active app in the Exhibit Tile.
    // This is usefful to keep the last opned app in the Exhibit Tile
    // when we set the active app as `APP_SCREENSHARE` or `APP_TRANSCRIPTS`.
    if (
      data.activeApp === APP_EXHIBIT_MANAGER ||
      data.activeApp === APP_EXHIBIT_EDITOR ||
      data.activeApp === APP_SPLASH
    ) {
      setActiveStageApp(data.activeApp);
    }
  }, [data.activeApp]);

  React.useEffect(() => {
    const _isHost = data.role === "host";
    setIsHost(_isHost);
  }, [data.role]);

  /**
   * Event handler for when the Exhibit Manager is closed.
   */
  const handleExhibitManagerClose = () => {
    setLaunchedExhibit(data.launchedExhibit);

    if (!isFullscreen) {
      setActiveApp(APP_SPLASH);
    }

    emitExhibitManagerClosed(getIdentity().username, data.eventId, isHost);
  };

  const handleExhibitEditorClose = () => {
    setLaunchedExhibit(data.launchedExhibit);
    if (data.isAppExpanded) {
      setActiveApp(APP_SPLASH);
      expandActiveApp(true);
    } else {
      setActiveApp(APP_SPLASH);
    }

    const isHost = data.role === "host" ? true : false;
    emitExhibitEditorClosed(data.myContact.email, data.eventId, isHost);
  };

  const mapExhibitNumberToName = {
    1: "advanced",
    2: "simple",
    0: "noExhibit",
  };

  return (
    <>
      {!loading && activeStageApp === "splash" && (
        <Splash isHost={isHost} mode={mapExhibitNumberToName[`${data?.event.exhibits}`]} />
      )}

      {activeStageApp === "exhibits" && (
        <Suspense fallback={<SimpleLoader />}>
          <ExhibitManagerDialog
            eventId={data.eventId}
            onClose={handleExhibitEditorClose}
            style={{
              backgroundColor: "#ffffff",
              height: "100vh",
            }}
          ></ExhibitManagerDialog>
        </Suspense>
      )}

      {activeStageApp === "exhibitEditor" && (
        <Suspense fallback={<SimpleLoader />}>
          <ExhibitEditorDialog
            onClose={handleExhibitEditorClose}
            eventId={data.eventId}
            fileId={data?.launchedExhibit?.fileId}
            presenter={data?.exhibitPresenter?.username}
            style={{
              height: "100vh",
              padding: "0px",
            }}
          ></ExhibitEditorDialog>
        </Suspense>
      )}

      {/* We need to add a <div> overlay to make resizing with Mosaic smoother. */}
      <div
        style={{
          visibility: data.resizeOverlay ? "visible" : "hidden",
          zIindex: "100",
          position: "absolute",
          top: "0",
          right: "0",
          backgroundColor: "rgba(0, 0, 0, 0.3)",
          width: "100%",
          height: "100%",
          textAlign: "center",
        }}
      ></div>
    </>
  );
};

/**
 * The Zoom tile.
 *
 * @returns
 */
const ZoomTile = () => {
  const { data } = React.useContext(DataContext);

  return (
    <>
      <Box style={{ height: "100%", width: "100%" }}>
        <Iframe
          url={`/rm/${data.event.acMeetingUrl.replaceAll("/", "")}/meeting`}
          id="zoom-iframe"
          className="meeting-iframe"
          display="initial"
          position="relative"
          styles={{ height: "100%", width: "100%", borderStyle: "none" }}
        />
      </Box>

      {/* We need to add the <div> overlay to make resizing with Mosaic smoother. */}
      <div
        style={{
          visibility: data.resizeOverlay ? "visible" : "hidden",
          zIindex: "100",
          position: "absolute",
          top: "0",
          right: "0",
          backgroundColor: "rgba(0, 0, 0, 0.3)",
          width: "100%",
          height: "100%",
          textAlign: "center",
        }}
      ></div>
    </>
  );
};

/**
 * The TranscriptsViewer IFrame - We need the TranscriptViewer to have its own
 * CPU and memory reserved since it uses a fair amount of CPU to render.
 *
 * The fix is based on the ‘Origin-Agent-Cluster' HTTP Header and an iframe for the ExhibitsEditor component.
 * Please see this article for more information:  https://web.dev/origin-agent-cluster/
 *
 * By moving the TranscriptViewer into its own iframe and passing the ‘Origin-Agent-Cluster’ HTTP Header,
 * browsers that support this header will know to allocate additional/separate CPU and memory resources
 * for the TranscriptViewer.
 *
 * @returns
 */

const TranscriptsTileIframe = ({ isFullscreen = false }) => {
  const { data } = React.useContext(DataContext);

  return (
    <>
      <Iframe
        url={`${process.env.REACT_APP_TRANSCRIPTVIEWER_URL}/rm/${data.event.acMeetingUrl.replaceAll(
          "/",
          ""
        )}/transcripts`}
        id="transcripts-iframe"
        className="transcripts-iframe"
        display="initial"
        position="relative"
        allow="camera *;microphone *"
        styles={{ overflowY: "auto", height: "100%", width: "100%", borderWidth: "0px" }}
      />
      {/* We need to add a <div> overlay to make resizing with Mosaic smoother. */}
      <div
        style={{
          visibility: data.resizeOverlay ? "visible" : "hidden",
          zIindex: "100",
          position: "absolute",
          top: "0",
          right: "0",
          backgroundColor: "rgba(0, 0, 0, 0.3)",
          width: "100%",
          height: "100%",
          textAlign: "center",
        }}
      ></div>
      {}
    </>
  );
};

/**
 * The Transcripts tile.
 *
 * @returns
 */
export const TranscriptsTile = () => {
  const [loading, setLoading] = React.useState(true);
  const [notAuthorized, setNotAuthorized] = React.useState();
  const [eventId, setEventId] = React.useState(-1);
  const { meetingUrl } = useParams();
  const { getEventByMeetingUrl } = React.useContext(DataContext);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        // QW-XXX This should be refactored!
        // console.log("TranscriptsTile.Getting logged-in user's identity...");
        // const identity = JSON.parse(getIdentity());
        // const username = identity.username;
        // const name = identity.name;

        // // Set MyContact
        // const contactId = JSON.parse(getIdentity()).contactId;
        // const contact = await getContact(contactId);
        // setData({ myContact: contact });

        // Get event by meetingUrl
        console.log("ExhibitsTile.Getting event by meetingUrl...");
        const results = await getEventByMeetingUrl(meetingUrl);
        const _event = results.event;
        const _case = results.myCase;
        setEventId(_event?.eventId);
        console.log("TranscriptsTile.Event fetched...", _event);
      } catch (error) {
        setNotAuthorized(true);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <Suspense fallback={<SimpleLoader />}>
      {eventId != -1 && (
        <>
          <TranscriptsViewerDialog
            open={() => console.log("openTranscriptsViewerDialog")}
            onClose={() => console.log("closeTranscriptsViewerDialog")}
            eventId={eventId}
          ></TranscriptsViewerDialog>
        </>
      )}
    </Suspense>
  );
};

/**
 * Exhibit Splash screen.
 * @returns
 */
export const Splash = ({ isHost, mode }) => {
  const classes = useStyles();
  const { data, setActiveApp, expandActiveApp, setExhibitPresenter, setLockExhibits } = React.useContext(DataContext);

  const handleLaunchActiveApp = (selectedApp) => {
    console.log("Launching active application...", selectedApp);

    // Set the exhibit presenter and active app.
    const presenter = {
      name: `${data.myContact.firstName} ${data.myContact.lastName}`,
      username: data.myContact.email,
    };

    setExhibitPresenter(presenter);
    const isExhibitTileActive =
      data.activeApp === APP_SPLASH || data.activeApp === APP_EXHIBIT_EDITOR || data.activeApp === APP_EXHIBIT_MANAGER;

    if (data.isAppExpanded && isExhibitTileActive) {
      setActiveApp(selectedApp);
      expandActiveApp(true);
    } else {
      setActiveApp(selectedApp);
    }

    // Emit the activeApp changed.  This will notify other
    // socket.io clients.
    emitActiveAppChanged(data.event, selectedApp, presenter);
  };

  const typographyText = React.useMemo(() => {
    if (mode === "simple") {
      return (
        <Typography variant="body2" color="textSecondary" component="p" style={{ cursor: "default" }}>
          Presenters can manage and publish exhibits from a private workspace. Attendees can view and download published
          exhibits in the Final Exhibits folder. Click the button below to get started.
        </Typography>
      );
    } else if (mode === "advanced") {
      return (
        <Typography variant="body2" color="textSecondary" component="p" style={{ cursor: "default" }}>
          Privately manage exhibits in an easy-to-use interface designed to mirror file explorer. Preview any exhibits
          before presenting and easily share with colleagues. Launch exhibits into presentation mode, with the ability
          to make annotations, mark with an exhibit sticker and save into record.
        </Typography>
      );
    } else {
      return null;
    }
  }, [mode]);

  React.useEffect(() => {
    console.log("XXXXX setLockExhibits to false");
    setLockExhibits(false);
  }, []);

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        style={{ height: "100vh", backgroundColor: "#292a2d" }}
      >
        <Card className={classes.card} variant="outlined">
          <Box className={classes.cardContentWrapper}>
            <Default>
              <CardMedia className={classes.media}>
                <img src="/images/splash_exhibits.png" width="500px" />
              </CardMedia>
            </Default>
            <CardContent className={classes.cardContent}>
              {(isHost || mode === "simple") && (
                <>
                  <Typography gutterBottom className={classes.title}>
                    Exhibits
                  </Typography>
                  {typographyText}
                </>
              )}

              {!isHost && mode === "advanced" && (
                <Typography gutterBottom className={classes.title} style={{ textAlign: "center" }}>
                  No Exhibits are currently being presented.
                </Typography>
              )}
            </CardContent>
          </Box>
          {isHost && (
            <CardActions className={classes.cardActions}>
              <Button
                size="small"
                variant="contained"
                onClick={() => handleLaunchActiveApp("exhibits")}
                disabled={data.lockExhibits}
              >
                {mode === "simple" ? "View Exhibits" : "Present an Exhibit"}
              </Button>
            </CardActions>
          )}

          {!isHost && mode === "simple" && (
            <CardActions className={classes.cardActions}>
              <Button
                size="small"
                variant="contained"
                onClick={() => handleLaunchActiveApp("exhibits")}
                disabled={data.lockExhibits}
              >
                {mode === "simple" ? "View Exhibits" : "Present an Exhibit"}
              </Button>
            </CardActions>
          )}
        </Card>
      </Box>
    </>
  );
};

export const NotAuthorizedMessage = () => {
  const classes = useStyles();
  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignContent="center"
        alignItems="center"
        style={{ height: "90vh", paddingLeft: "50px", paddingRight: "50px" }}
      >
        <Typography className={classes.splashTitle}>Not Authorized for Event</Typography>
        <Typography className={classes.subTitle}>
          You do not have permissions to access this Event. Please contact the Event Host to add you as a participant.
          <br />
          <br />
        </Typography>
      </Box>
    </>
  );
};

const ExhibitManagerLockedDialog = ({ open, handleClose, exhibitPresenter }) => {
  return (
    <>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <CRXDialogTitle onClick={handleClose} title="HusebyConnect" />

        <DialogContent>
          <DialogContentText>
            <Box width={400}>{exhibitPresenter} is selecting an exhibit to present.</Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DesktopIndex;
