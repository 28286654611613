/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms 
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import React, { useEffect, useState } from "react";
import ApplicationMenu from "./ApplicationMenu";
import HelpMenu from "./HelpMenu";
import UserMenu from "./UserMenu";
import "../../huseby-header.css";

const Header = ({ onTabChanged }) => {
  const [index, setIndex] = useState(0);

  const handleChange = (event, newValue) => {
    setIndex(newValue);
    onTabChanged(event, newValue);
  };

  const handleMobileAppSelected = (selectedApp) => {
    if (selectedApp === "meeting") {
      onTabChanged(null, 0);
    } else if (selectedApp === "exhibits") {
      onTabChanged(null, 1);
    } else if (selectedApp === "transcripts") {
      onTabChanged(null, 2);
    }
  };

  return (
    <>
      <div className="header-container">
        <div className="header-menubar">
          <ApplicationMenu onMobileAppSelected={handleMobileAppSelected} />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            // justifyContent: "flex-end",
            marginRight: "10px",
          }}
        >
          <HelpMenu />
          <UserMenu />
        </div>
      </div>
    </>
  );
};

export default Header;
