/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import React from "react";
import { withStyles } from "@mui/styles";
import { Button, IconButton, Menu, MenuItem, ListItemText, Checkbox, Tooltip } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { DataContext } from "../../services/DataService";
import { Default, Mobile } from "./Responsive";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
    // height: "200px",
    minWidth: "250px",
    width: "400px",
    padding: "10px",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.secondary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.black,
      },
    },
  },
}))(MenuItem);

const ApplicationMenu = ({ onMobileAppSelected }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { data, setShowExhibitsTile, setShowZoomTile, setShowTranscriptsTile } = React.useContext(DataContext);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleScreenshareClick = () => {
    setShowZoomTile(!data.showZoomTile);
    setAnchorEl(null);
  };

  const handleExhibitEditorClick = () => {
    setShowExhibitsTile(!data.showExhibitsTile);
    setAnchorEl(null);
  };

  const handleTranscriptViewerClick = () => {
    setShowTranscriptsTile(!data.showTranscriptsTile);
    setAnchorEl(null);
  };

  const handleMobileAppSelected = (selectedApp) => {
    onMobileAppSelected && onMobileAppSelected(selectedApp);
    setAnchorEl(null);
  };

  return (
    <>
      <Default>
        <Button variant="contained" color="primary" onClick={handleClick}>
          Applications
        </Button>
        <StyledMenu
          id="customized-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          style={{ width: "400px" }}
        >
          {data?.event?.exhibits > 0 && (
            <StyledMenuItem>
              <Checkbox checked={data.showExhibitsTile} onChange={handleExhibitEditorClick} color="primary" />
              <ListItemText primary="Exhibits" onClick={handleExhibitEditorClick} />
            </StyledMenuItem>
          )}
          {data?.event?.transcripts !== 0 &&
            (data.eventParticipant.grantViewTranscripts ||
              data.eventParticipant.eventParticipantType.eventParticipantTypeId === 1) && (
              <StyledMenuItem>
                <Checkbox checked={data.showTranscriptsTile} onChange={handleTranscriptViewerClick} color="primary" />
                <ListItemText primary="Transcripts" onClick={handleTranscriptViewerClick} />
              </StyledMenuItem>
            )}
          {data?.event?.enableMeeting && (
            <StyledMenuItem>
              <Checkbox checked={data.showZoomTile} onChange={handleScreenshareClick} color="primary" />
              <ListItemText primary="Video, Audio, Screensharing" onClick={handleScreenshareClick} />
            </StyledMenuItem>
          )}
        </StyledMenu>
      </Default>
      <Mobile>
        <Tooltip title="Click and select the Application to display">
          <IconButton variant="contained" size="small" onClick={handleClick}>
            <MenuIcon fontSize="large" color="secondary" />
          </IconButton>
        </Tooltip>
        <StyledMenu
          id="customized-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          style={{ width: "400px" }}
        >
          {data?.event?.exhibits > 0 && (
            <StyledMenuItem>
              <ListItemText primary="Exhibits" onClick={() => handleMobileAppSelected("exhibits")} />
            </StyledMenuItem>
          )}
          {data?.event?.transcripts !== 0 &&
            (data.eventParticipant.grantViewTranscripts ||
              data.eventParticipant.eventParticipantType.eventParticipantTypeId === 1) && (
              <StyledMenuItem>
                <ListItemText primary="Transcripts" onClick={() => handleMobileAppSelected("transcripts")} />
              </StyledMenuItem>
            )}
          {data?.event?.enableMeeting && (
            <StyledMenuItem>
              <ListItemText primary="Video, Audio, Screensharing" onClick={() => handleMobileAppSelected("meeting")} />
            </StyledMenuItem>
          )}
        </StyledMenu>

        {/* <Mobile>
            <Tabs value={index} onChange={handleChange} textColor="secondary" indicatorColor="secondary">
              <Tab label="Meeting" {...a11yProps(1)} />
              <Tab label="Exhibits" {...a11yProps(2)} />
              <Tab label="Transcripts" {...a11yProps(3)} />
            </Tabs>
          </Mobile> */}
      </Mobile>
    </>
  );
};

export default ApplicationMenu;
