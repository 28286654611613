/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms 
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import theme from "./theme";
import App from "./App";
import { DndProvider, DndContext, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import "react-mosaic-component/react-mosaic-component.css";
import "./index.css";
import "./huseby-mosaic.css";

import * as serviceWorker from "./serviceWorker";
import { AuthProvider } from "@cirrux888/huseby-client-auth";
import { SocketIOProvider } from "@cirrux888/huseby-client-exhibit-editor";
import { DataProvider } from "./services/DataService";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { TranscriptProvider, AwsTranscribeProvider } from "@cirrux888/huseby-client-real-time-transcripts";

import { LicenseInfo } from "@mui/x-license-pro";

// Set the MUI license for DataGridPro
LicenseInfo.setLicenseKey(`${process.env.REACT_APP_MUI_LICENSE_PRO}`);

ReactDOM.render(
  <React.StrictMode>
    <div id="app">
      <BrowserRouter>
        <AuthProvider>
          <DataProvider>
            <TranscriptProvider>
              <AwsTranscribeProvider>
                <SocketIOProvider>
                  <ThemeProvider theme={theme}>
                    <StyledEngineProvider injectFirst>
                      <DndProvider backend={HTML5Backend}>
                        <App />
                      </DndProvider>
                    </StyledEngineProvider>
                  </ThemeProvider>
                </SocketIOProvider>
              </AwsTranscribeProvider>
            </TranscriptProvider>
          </DataProvider>
        </AuthProvider>
      </BrowserRouter>
    </div>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
