/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import React, { Suspense, lazy } from "react";
import { useLocation } from "react-router-dom";
import { Box, Divider } from "@mui/material";
import { Switch, Route } from "react-router-dom";
import { ThemeProvider } from "@mui/styles";
import { Login } from "@cirrux888/huseby-client-auth";
import { ZoomMeetingProvider } from "@cirrux888/huseby-client-zoom";
import { PrivateRoute, ResetPassword, AuthProvider } from "@cirrux888/huseby-client-auth";
import { Microphone } from "@cirrux888/huseby-client-real-time-transcripts";
import theme from "./theme";
import SimpleLoader from "./components/common/SimpleLoader";
import CacheBuster from "./CacheBuster";

const Index = lazy(() => import("./components/Index"));
const Meeting = lazy(() => import("./components/zoom/Meeting"));
import { ExhibitsTile } from "./components/DesktopIndex";
import { TranscriptsTile } from "./components/DesktopIndex";

const App = () => {
  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        console.log("CacheBuster.loading", loading);
        console.log("CacheBuster.isLatestVersion", isLatestVersion);
        console.log("SharedArrayBuffer enabled: ", typeof SharedArrayBuffer === "function");

        if (loading) return null;
        if (!loading && !isLatestVersion) {
          // You can decide how and when you want to force reload
          refreshCacheAndReload();
        }
        return (
          <AuthProvider>
            <ZoomMeetingProvider>
              <ThemeProvider theme={theme}>
                <Suspense fallback={<SimpleLoader />}>
                  <Switch>
                    <Route path="/login">
                      <Login />
                    </Route>
                    <Route path="/reset">
                      <ResetPassword />
                    </Route>
                    <PrivateRoute path="/mic">
                      <Suspense fallback={<SimpleLoader />}>
                        <MicApp />
                      </Suspense>
                    </PrivateRoute>
                    <PrivateRoute path="/rm/:meetingUrl/meeting">
                      <Suspense fallback={<SimpleLoader />}>
                        <Meeting />
                      </Suspense>
                    </PrivateRoute>
                    <PrivateRoute path="/rm/:meetingUrl/exhibitsApp">
                      <Suspense fallback={<SimpleLoader />}>
                        <ExhibitsTile />
                      </Suspense>
                    </PrivateRoute>
                    <PrivateRoute path="/rm/:meetingUrl/transcripts">
                      <Suspense fallback={<SimpleLoader />}>
                        <TranscriptsTile />
                      </Suspense>
                    </PrivateRoute>
                    <PrivateRoute path="/rm/:meetingUrl/:fullscreenApp">
                      <Suspense fallback={<SimpleLoader />}>
                        <Index />
                      </Suspense>
                    </PrivateRoute>
                    <PrivateRoute path="/rm/:meetingUrl">
                      <Suspense fallback={<SimpleLoader />}>
                        <Index />
                      </Suspense>
                    </PrivateRoute>
                    <Route path="/" component={NotFound} />
                  </Switch>
                </Suspense>
              </ThemeProvider>
            </ZoomMeetingProvider>
          </AuthProvider>
        );
      }}
    </CacheBuster>
  );
};

const MicApp = () => {
  const [eventId, setEventId] = React.useState(-1);

  React.useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    setEventId(searchParams.get("eventId"));
  }, []);
  return (
    <Box style={{ height: "100px", backgroundColor: "#ffffff" }}>
      {eventId != -1 && <Microphone eventId={eventId} />}
    </Box>
  );
};

const NotFound = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      style={{ backgroundColor: "#ffffff", height: "100vh" }}
    >
      <h1>404</h1>
      <Divider />
      <h3>We're sorry! This page is not available. Please try a different event url.</h3>
    </Box>
  );
};

export default App;
