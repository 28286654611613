/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms 
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormLabel,
  FormControlLabel,
  Snackbar,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { Alert } from "@mui/lab";
import { get, isEmpty } from "lodash";
import Joi from "joi-browser";
import { AuthContext } from "@cirrux888/huseby-client-auth";
import { DataContext } from "../../services/DataService";
import CRXDialogTitle from "../common/dialog/CRXDialogTitle";
import { validate, validateProperty } from "../common//validateUtilities";

const SupportTicket = ({ open, handleClose }) => {
  const { auth } = React.useContext(AuthContext);
  const { data, createTicket } = React.useContext(DataContext);
  const [subject, setSubject] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [errors, setErrors] = React.useState(null);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [priorityType, setPriorityType] = React.useState("2");

  React.useEffect(() => {
    setSubject("");
    setMessage("");
    setErrors([]);
  }, [open]);

  const schema = {
    priority: Joi.string().required(),
    subject: Joi.string().required().trim(true).min(0).max(255).label("Subject"),
    message: Joi.string().required().trim(true).min(0).max(255).label("Message"),
    contactName: Joi.string().required().trim(true),
    contactEmail: Joi.string().required().trim(true),
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const priority = e.target.priority.value;
      const subject = e.target.subject.value;
      const message = e.target.message.value;
      const contactName = `${get(auth, ["identity", "firstName"])} ${get(auth, ["identity", "lastName"])}`;
      const contactEmail = get(auth, ["identity", "username"]);

      const ticket = {
        priority: priority,
        subject: subject,
        message: message,
        contactName: contactName,
        contactEmail: contactEmail,
      };

      const errs = validate(ticket, schema);
      if (errs != null) {
        setErrors(errs);
        return;
      }

      setOpenSnackbar(true);
      handleClose();

      await createTicket(ticket);
    } catch (ex) {
      const errorMsg = ex.response.data.message;
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <form noValidate onSubmit={handleSubmit}>
          <CRXDialogTitle onClick={handleClose} title="Create New Support Ticket" />

          <DialogContent>
            <DialogContentText>Please fill out the form below to submit a new support ticket.</DialogContentText>

            <Box m={1}>
              <RadioGroup
                aria-label="priority"
                name="priority"
                row
                onChange={(e) => setPriorityType(e.target.checked)}
                defaultValue={priorityType}
              >
                <Box display="flex" alignItems="center" justifyContent="center" mr={4}>
                  Priority:
                </Box>
                <FormControlLabel value={"3"} control={<Radio color="primary" />} label="High" />
                <FormControlLabel value={"2"} control={<Radio color="primary" />} label="Medium" />
                <FormControlLabel value={"1"} control={<Radio color="primary" />} label="Low" />
              </RadioGroup>
            </Box>
            <TextField
              id="subject"
              label="Subject"
              name="subject"
              variant="outlined"
              required
              fullWidth
              error={errors && !isEmpty(errors["subject"])}
              helperText={errors && errors["subject"]}
              onChange={(e) => {
                setSubject(e.currentTarget.value);
                validateProperty(schema, e.currentTarget, setErrors);
              }}
              autoFocus
            />
            <br />
            <br />
            <TextField
              id="message"
              label="Message"
              name="message"
              multiline
              rows={4}
              defaultValue=""
              variant="outlined"
              required
              fullWidth
              error={errors && !isEmpty(errors["message"])}
              helperText={errors && errors["message"]}
              onChange={(e) => {
                setMessage(e.currentTarget.value);
                validateProperty(schema, e.currentTarget, setErrors);
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="contained" color="primary">
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary" disabled={!isEmpty(errors)}>
              Send
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        autoHideDuration={4000}
        message="Your support ticket has been successfully submitted."
      >
        <Alert onClose={handleCloseSnackbar} severity="success">
          Your support ticket has been successfully submitted.
        </Alert>
      </Snackbar>
    </>
  );
};

export default SupportTicket;
