/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms 
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, CircularProgress } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    backgroundColor: theme.palette.background.default,
  },
  progress: {
    color: "#71ee33",
  },
}));

const SimpleLoader = () => {
  const classes = useStyles();

  return (
    <>
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" className={classes.root}>
        <CircularProgress size={50} className={classes.progress} />
      </Box>
    </>
  );
};

export default SimpleLoader;
