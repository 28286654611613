/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms 
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  components: {
    MuiLink: {
      defaultProps: {
        underline: "hover",
      },
    },
    MuiTooltip: {
      styleOverrides: {
        popper: {
          marginLeft: 0,
          marginTop: 0,
        },
        tooltip: {
          backgroundColor: "pink",
          color: "#000000",
          boxShadow: "0 5px 5px rgba(0, 0, 0, 0.5)",
        },
      },
    },
    MuiTab: {
      root: {
        overflow: "none",
        whiteSpace: "nowrap",
      },
    },
    MuiSlider: {
      thumb: {
        height: 16,
        width: 16,
        background: "linear-gradient(180deg, rgba(175,23,48,1) 60%, rgba(111,15,31,1) 100%)",
        border: "1px solid currentColor",
        marginTop: -4,
        marginLeft: -12,
        "&:focus,&:hover,&$active": {
          boxShadow: "inherit",
        },
      },
      active: {},
      track: {
        height: 8,
        borderRadius: 4,
        color: "#e4e4e4",
      },
      rail: {
        height: 8,
        borderRadius: 4,
        opacity: 1,
        color: "#e4e4e4",
      },
    },
    MuiTextField: {
      root: {
        variant: "outlined",
        margin: 4,
      },
    },
    MuiTableCell: {
      root: {
        backgroundColor: "#ffffff",
      },
    },
  },
  palette: {
    primary: {
      main: "#af1730",
      // main: "#0b2547",
    },
    secondary: {
      main: "#e4e4e4",
    },
    background: {
      default: "#ffffff",
    },
  },
});

export default theme;
