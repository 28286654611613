/**
////////////////////////////////////////////////////////////////////////////////
//
// HUSEBY INC
// Copyright 2021 Huseby, Inc.
// All Rights Reserved.
//
// NOTICE: Huseby, Inc permits you to use this file in in accordance with the terms 
// of the license agreement accompanying it.  Do not modify, sell or distribute
// without the expressed, written consent of Huseby, Inc.
//
////////////////////////////////////////////////////////////////////////////////
*/

import React from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, Snackbar } from "@mui/material";
import { Alert } from "@mui/lab";
import { AuthContext } from "@cirrux888/huseby-client-auth";
import { DataContext } from "../../services/DataService";
import CRXDialogTitle from "../common/dialog/CRXDialogTitle";
import { emitExhibitManagerClosed } from "../useSocket";

// QW-TODO: Need to place constants in a common class.
const APP_SPLASH = "splash";

const ManageRoom = ({ open, onClose }) => {
  const { auth, getIdentity } = React.useContext(AuthContext);
  const { data, setActiveApp, setLockExhibits } = React.useContext(DataContext);
  const [loading, setLoading] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  const handleSubmit = async (e) => {
    try {
      setLoading(true);
      console.log("Resetting the room...");
      setActiveApp(APP_SPLASH);
      emitExhibitManagerClosed(getIdentity().username, data.eventId, true);
      setLockExhibits(false);
      // setOpenSnackbar(true);
      console.log("Room reset.");
    } finally {
      setLoading(false);
      onClose();
    }
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
        <form noValidate onSubmit={handleSubmit}>
          <CRXDialogTitle onClick={onClose} title="Reset Room" />

          <DialogContent>
            <DialogContentText>Click the Reset Button to reset the room state.</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} variant="contained" color="primary">
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Reset
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        autoHideDuration={4000}
        message="The room state has been reset."
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity="success">
          The room state has been reset.
        </Alert>
      </Snackbar> */}
    </>
  );
};

export default ManageRoom;
